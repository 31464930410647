.footer {
    margin-top: 100px; 
}

.image {
    height: 5rem;
}

.main_text {
    margin-top: 5rem;
    width: 70%;
    color: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.heading {
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 2rem;
}

.paragraph {
    padding-top: 2rem;
    color: #fff;
    font-size: 1.5rem;
}
