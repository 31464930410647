.margins {
  margin-left: 20px !important;
  width: 100%;
}

@media screen and (max-width: 770px) {
  .margins {
    /* margin-right: 5% !important; */
    padding: 0 !important;
    margin: 0 7% !important;
  }

  .label_name {
    float: left;
    font-weight: bold;
  }
}

@media screen and (max-width: 400px) {
  .margins {
    /* margin-left: -1% !important; */
    /* margin-right: 0% !important; */
  }

  .margins input {
    width: 100% !important;
    margin: auto;
  }
}
