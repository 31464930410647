.datesFromTo {
  width: 100%;
}

.dateTo,
.dateFrom {
  height: 48px;
  padding-right: 8px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.dateTo {
  padding-left: 8px;
}

.dateFrom {
  border-right: 1px solid #e6e6e6;
}

.icon {
  margin-top: -2px;
  padding-right: 8px;
}