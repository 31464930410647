.wrapper {
  display: flex;
  justify-content: center;
  height: 100%;
}

.main_search_width {
  max-width: 900px;
  margin: auto;
}

.wrapper :global .DateRangePickerInput {
  height: 100%;
  border: 0;
  border-radius: 0;
  overflow: hidden;
}

.wrapper :global .DateRangePicker {
  height: 100%;
}

.wrapper :global .DateRangePicker > div {
  height: 100%;
}

.wrapper :global .DateInput_input {
  height: 40px;
  padding: 11px 10px 9px;
  /* height: 20px; */
}

.wrapper :global .DateInput_input__focused {
  border-color: transparent;
}

.rangePicker {
  margin-bottom: 0;
  height: 100%;
}

.wrapper .rangePicker__error :global .DateInput_input::placeholder {
  color: #ff4d4f;
}

.margin_top {
  margin-top: 8%;
}

.margin_top2 {
  margin-top: 15%;
}

.occupancyDropDown {
  margin-bottom: 0;
}

.occupancyDropDown :global .dropdown {
  height: 100%;
  margin-top: 0px;
}

.occupancyDropDown :global .dropdown-toggle {
  height: 100%;
  border: none;
  border-radius: 0px;
  padding-right: 5%;
}

.occupancyDropDown :global .btn-primary:not(:disabled):not(.disabled):focus {
  border: none;
}

.occupancyDropDown :global .btn-primary.dropdown-toggle {
  width: 195px;
}
.occupancyDropDown :global .btn-primary.dropdown-toggle:hover {
  width: 195px;
}

.occupancyDropDown :global .show > .btn-primary.dropdown-toggle {
  border: none;
}

.occupancyDropDown :global .dropdown-menu.show {
  width: 305px;
  max-height: 305px;
  overflow: auto;
}

.fa-search {
  color: #fff;
}

.searchBtn:global.btn-primary:hover,
.searchBtn:global.btn-primary:focus,
.searchBtn:global.btn-primary:active {
  transition: all 200ms ease;
  background: rgb(0 190 10 / 90%);
}

.searchBtn:global.btn-primary:not(:disabled):not(.disabled):active {
  background: rgb(0 190 10 / 90%);
}

.margin_scroll {
  margin-bottom: 0;
}

.search_control {
  /* margin-left: 14%; */
  margin-top: 1.2%;
}

.modal_1 {
  position: absolute !important;
  left: 11% !important;
  top: 100% !important;
  z-index: 1 !important;
  background: #ffffff !important;
  border-radius: 32px !important;
  box-shadow: 0px 6px 20px rgb(0 0 0 / 20%) !important;
  margin-top: 12px !important;
  max-height: -webkit-calc(100vh - 220px) !important;
  max-height: -moz-calc(100vh - 220px) !important;
  max-height: calc(100vh - 220px) !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  padding: 16px 32px !important;
}

.modal_2 {
  width: 70%;
}

.modal_3 {
  padding: 8px 0px !important;
  margin: 0px -32px -8px !important;
  width: 500px !important;
}

.modal_4 {
  margin: 0px 0px 16px !important;
  padding: 0px !important;
}

.modal_5 {
  list-style-type: none !important;
  margin: 0px !important;
  padding: 8px 32px 2px !important;
}

.modal_6 {
  text-transform: uppercase !important;
  font-size: 12px !important;
  line-height: 12px !important;
  font-weight: 800 !important;
}

.modal_7 {
  list-style-type: none !important;
  cursor: pointer !important;
  padding: 8px 32px !important;
}

.modal_8 {
  text-decoration: none !important;
  -webkit-box-align: center !important;
  -webkit-box-direction: reverse !important;
  -webkit-box-orient: horizontal !important;
  display: flex !important;
  width: 100% !important;
  flex-direction: row-reverse !important;
  align-items: center !important;
  padding: 8px 24px !important;
  margin-top: 8px !important;
  box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px !important;
  border-radius: 32px !important;
  height: 58px !important;
  border: 1px solid rgb(221, 221, 221);
}

.modal_8:hover {
  box-shadow: rgb(0 0 0 / 20%) 0px 6px 16px !important;
}

.modal_9 {
  -webkit-box-pack: center !important;
  -webkit-box-direction: normal !important;
  -webkit-box-orient: vertical !important;
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
}

.modal_10 {
  overflow: clip !important;
  overflow-x: clip !important;
  overflow-y: clip !important;
}
.modal_10 {
  /* -webkit-text-fill-color: transparent !important; */
  background: linear-gradient(
      90deg,
      rgb(111, 1, 156) 0%,
      rgb(198, 1, 126) 135.12%
    )
    text !important;
}
.modal_10 {
  font-size: 18px !important;
  line-height: 20px !important;
  font-weight: 800 !important;
  color: rgb(34, 34, 34) !important;
  max-height: 120px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 6 !important;
  -webkit-box-orient: vertical !important;
}

/* .modal_10 { */
/* overflow:auto; */
/* -webkit-text-fill-color: transparent !important; */
/* background: linear-gradient(90deg, rgb(111, 1, 156) 0%, rgb(198, 1, 126) 135.12%) text !important;
  font-size: 18px !important;
  line-height: 20px !important;
  font-weight: 800 !important;
  color: #4F02A4 !important;
  max-height: 120px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 6 !important;
  -webkit-box-orient: vertical !important;
} */

@media (max-width: 1200px) {
  .search_control {
    margin-left: 0%;
    margin-right: 0%;
  }

  .rangePicker {
    width: 30%;
    margin-right: 0;
    margin-left: 0;
  }

  .occupancyDropDown {
    margin-right: 0%;
  }

  .occupancyDropDown :global .dropdown-toggle {
    height: 100%;
    border: none;
    border-radius: 0px;
    padding-right: 20%;
  }
}

@media screen and (max-width: 980px) {
  .main_search_width {
    max-width: 700px !important;
    margin: auto;
  }
}

@media screen and (max-width: 770px) {
  .main_search_width {
    max-width: 80% !important;
  }

  .wrapper {
    margin-top: -200px;
  }

  .search_index {
    z-index: 20 !important;
  }

  .rangePicker {
    width: 100%;
    margin: 0 7%;
  }

  .occupancyDropDown {
    margin-bottom: 20px;
  }

  .occupancyDropDown :global .dropdown {
    height: 100%;
    margin-top: -15px;
    margin-left: 15%;
  }

  .occupancyDropDown :global .dropdown-toggle {
    height: 100%;
    border: 1px solid;
    border-color: gray;
    border-radius: 0px;
    padding-right: 5%;
  }

  .search_control {
    margin-left: 50%;
  }

  .margin_top {
    margin-top: 5%;
  }

  .margin_top2 {
    margin-top: 0%;
  }
}

@media screen and (max-width: 570px) {
  .modal_1 {
    position: absolute !important;
    left: 5% !important;
    top: 100% !important;
    z-index: 1 !important;
    background: #ffffff !important;
    border-radius: 32px !important;
    box-shadow: 0px 6px 20px rgb(0 0 0 / 20%) !important;
    margin-top: 12px !important;
    max-height: -webkit-calc(100vh - 220px) !important;
    max-height: -moz-calc(100vh - 220px) !important;
    max-height: calc(100vh - 220px) !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
    padding: 16px 32px !important;
  }
  .modal_3 {
    padding: 8px 0px !important;
    margin: 0px -32px -8px !important;
    width: 400px !important;
  }

  .main_search_width {
    max-width: 95% !important;
  }

  .rangePicker {
    width: 100%;
    /* padding: 10px 15px; */
    margin: 1% 7%;
  }

  .occupancyDropDown {
    /* margin-bottom: 0; */
    margin-top: 2% !important;
    margin-left: 2% !important;
  }
}

@media screen and (max-width: 450px) {
  .modal_3 {
    padding: 8px 0px !important;
    margin: 0px -32px -8px !important;
    width: 300px !important;
  }

  .occupancyDropDown {
    margin-bottom: 0;
    margin-left: 1%;
    /* margin-left: 7% !important; */
  }

  .occupancyDropDown :global .dropdown {
    margin-top: -10px;
    /* padding-left: 10px; */
    /* margin-left: 10%; */
  }
}

@media screen and (max-width: 400px) {
  .margin_scroll {
    margin-bottom: 30%;
  }

  .occupancyDropDown {
    align-self: center;
    width: auto;
  }
}
