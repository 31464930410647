.container {
  border-top: 1px solid #dee2e6;
}

.containerExpanded {
  border-color: rgba(0, 0, 0, 0);
}

.facilities > div {
  margin: 0;
} 

.toggleButton {
  padding: 0;
  font-size: 12px;
}

.toggleButton:active, .toggleButton:focus {
  box-shadow: none;
}

