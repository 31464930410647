.model {
  display: block !important;
}

.name_show {
  display: flex;
  margin-bottom: 15px;
}

.logo_show {
  display: none;
}

.text_color {
  color: #606060;
  font-family: 'Roboto', sans-serif;
}

.btn_link {
  color: #fff !important;
  font-size: 1.2rem !important;
}

.btn_active {
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-width: 0.1rem !important;
  border-color: #fff !important;
}

.btn_link:hover {
  background-color: #dcdcdc !important;
  color: #333 !important;
}

.navbar_height {
  height: 80px !important;
}

.helping_logo {
  width: 160px;
  height: 25px;
}

.helping_logo_2 {
  width: 190px;
  height: 50px;
}

.name_margin {
  margin-bottom: 0;
}

@media screen and (max-width: 1070px) {
  .nav_margin {
    margin-right: -50%;
  }
}

@media screen and (max-width: 960px) {
  .model {
    display: block;
    padding-right: 16px;
    width: 100%;
  }

  .navbar_height {
    height: 60px !important;
  }

  .logo_padding {
    padding-bottom: 10px !important;
  }

  .name_margin {
    margin-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .show {
    display: none;
  }

  .model {
    display: block;
    padding-right: 16px;
    width: 100%;
  }

  .back {
    background-color: #fff;
    height: 100%;
  }
}

.navbar_container {
  width: 100%;
  background-color: #fff;
  margin: 0%;
}

@media screen and (max-width: 480px) {
  .name_show {
    display: none;
  }

  .logo_show {
    display: block;
    margin-bottom: 20px;
  }

  .helping_logo {
    width: 150px;
    height: 25px;
  }

  .helping_logo_2 {
    width: 170px;
    height: 50px;
  }
}
