.dropdownToggle {
  display: flex;
  width: 100%;
  height: 40px;
  padding-right: 8px;
  padding-left: 8px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  transition: all 200ms ease;
  background-color: white !important;
  color: var(--main-text-color);
}

.hiddenSelect {
  visibility: hidden;
  display: none;
} 

.dropdownButton {
  border: none;
}

.dropdownToggle,
.dropdownButton {
  background-color: white !important;
  color: var(--main-text-color);
}

.dropdownToggle:hover,
.dropdownToggle:focus,
.dropdownButton:hover,
.dropdownButton:focus {
  background-color: white !important;
  color: var(--main-text-color) !important;
  outline: none;
  box-shadow: none;
}

.dropdown:global > .btn-primary.dropdown-toggle,
.dropdownToggle:global.btn-primary:not(:disabled):not(.disabled):focus,
.dropdownToggle:global.btn-primary:not(:disabled):not(.disabled):active,
.dropdownButton:global.btn-primary:not(:disabled):not(.disabled):focus,
.dropdownButton:global.btn-primary:not(:disabled):not(.disabled):active {
  background-color: white;
  color: var(--main-text-color) !important;
  box-shadow: none;
  outline: none;
  border: 1px solid rgb(230, 230, 230);
  /* border-color: rgba(0, 0, 0, 0.25) ; */
}

.dropdown:global.show > .btn-primary.dropdown-toggle,
.dropdownToggle:global.btn-primary:not(:disabled):not(.disabled):focus {
  border-bottom: 2px solid #008489;
  margin-bottom: -1px;
}

.containerHorizontal,
.containerVertical {
  display: flex;
  align-items: center;
}

.containerVertical {
  flex-direction: column;
}

.dropdown,
.dropdownToggle {
  width: 100%;
}

.dropdown > .dropdownToggleInvalid:global.btn-primary.dropdown-toggle,
.dropdownToggleInvalid:global.btn-primary:not(:disabled):not(.disabled):focus,
.dropdownToggleInvalid:global.btn-primary:not(:disabled):not(.disabled):active,
.dropdownToggleInvalid:global.btn-primary:not(:disabled):not(.disabled):focus,
.dropdownToggleInvalid:global.btn-primary:not(:disabled):not(.disabled):active {
  border-color: #dc3545;
}

.dropdownToggle:empty:after {
  margin-right: 0;
  margin-left: auto;
}

.pulse {
  background: transparent;
  cursor: pointer;
  box-shadow: 0 0 0 #a593c8;
  animation: pulse 1s infinite;
}
.pulse:hover {
  animation: none;
}


@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 #a593c8;
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px #a593c8;
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 #a593c8;
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 #a593c8;
    box-shadow: 0 0 0 0 #a593c8;
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(11,41,83, 0);
      box-shadow: 0 0 0 10px rgba(11,41,83, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(11,41,83, 0);
      box-shadow: 0 0 0 0 rgba(11,41,83, 0);
  }
}
