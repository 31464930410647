.ratePlanPriceContainer {
  display: flex;
  flex-direction: row;
}

.ratePlanTaxes {
  font-size: 0.8rem;
}

.ratePlanPrice {
  white-space: nowrap;
}

.ratePlanPriceCell {
  flex: 2 1 50%;
}