.marker {
  color: #fff;
  background: #06bb24;
  border: 1px solid #45d45d;
  border-radius: 4px;
  font-family: Helvetica;
  font-size: 14px;
  color: #ffffff;
  min-width: 80px;
  padding: 7px 4px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  transform: translate(-50%, -50%);
}

.marker:hover,
.markerHighlited {
  background: #45d45d;
  border: 1px solid #62dd77;
}

.marker:hover .markerContent {
  display: none;
}

.marker:hover .markerOverlay {
  display: initial;
}

.markerOverlay {
  display: none;
}