.expandButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: -8px;
  padding: 0 0 8px;
  color: var(--cta-color);
  border-bottom: 1px solid rgba(36, 46, 66, 0.12);
}

.expandButton:global.btn:focus {
  box-shadow: none;
}

.expandButton:global.btn:hover {
  color: var(--cta-color);
}

.arrowIcon {
  transform: rotate(180deg);
  transition: 350ms ease-in-out;
}

.arrowIconInverted {
  transform: rotate(0deg);
}