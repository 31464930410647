.checkbox {
  display: none !important;
  
}

.checkboxLabel {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  cursor: pointer;
  margin-bottom: 0;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

}

.checkIcon {
  font-size: 16px;
}

.group {
  display: flex;
  margin: 0;
}

.label {
  margin-bottom: 0;
}

.pulse {
  background: transparent;
  cursor: pointer;
  box-shadow: 0 0 0 #a593c8;
  animation: pulse 1s infinite;
}
.pulse:hover {
  animation: none;
}


@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 #a593c8;
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px #a593c8;
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 #a593c8;
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 #a593c8;
    box-shadow: 0 0 0 0 #a593c8;
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(11,41,83, 0);
      box-shadow: 0 0 0 10px rgba(11,41,83, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(11,41,83, 0);
      box-shadow: 0 0 0 0 rgba(11,41,83, 0);
  }
}