.stickyContainer {
  position: -webkit-sticky;
  position: sticky;
  top: 56px;
  z-index: 5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1140px;
  padding-right: 15px;
  padding-left: 15px;
  pointer-events: none;
  justify-content: flex-end;
  align-items: flex-start;
  margin: auto;
}

.wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
}

.searchPanelWrapper {
  width: 32%;
  pointer-events: all;
  margin-top: 0px;
  background-color: #fff;
}

.searchPanelNoOffset {
  margin-top: 56px;
}

.searchSection {
  padding: 24px;
  border-style: solid;
  border-width: 1px;
  border-color: var(--border-color);
}

.searchRow {
  display: flex;
}

@media screen and (max-width: 991px) {
  .stickyContainer {
    position: static;
    height: auto;
  }
  
  .wrapper {
    position: relative;
    height: auto;
    width: auto;
  }

  .searchPanelWrapper {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .stickyContainer {
    padding-right: 0px;
    padding-left: 0px;
  }

  .searchPanelWrapper {
    margin-top: 0px; 
  }
}

@media screen and (max-width: 575px) {
  .searchSection {
    position: relative;
    min-width: auto;
    max-width: auto;
    left: auto;
    transform: none;
    bottom: auto;
  }

  .searchRow {
    flex-direction: column;
  }
}
