.input {
  width: 100%;
  height: 40px;
  margin-bottom: 0;
  padding-right: 8px;
  padding-left: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0,0,0,.25);
  border-radius: 2px;
  background-color: #fff;
  transition: all .2s ease;
}