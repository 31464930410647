.accordion {
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 0.3em;
}

.heading {
  display: flex;
  justify-content: center;
}

.card-header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.head_color {
  color: #fff;
}

.Faq {
  background-color: #fff;
  padding-top: 2em;
  padding-bottom: 2em;
}

.card {
  border-radius: 0px !important;
}