.list {
    list-style-type:square;
    font-size: 1.2rem;
    line-height: 25px;
}

.span_text {
    text-decoration: underline;
    font-weight: bold;
}

.text_color {
    color: 	#606060;
    font-family: 'Roboto', sans-serif;
    text-decoration: underline; 
}
 
.line_height {
    line-height: 20px;
}

.margins {

}

@media screen and (max-width: 767px) {
    .margins {
        margin-top: 20rem;
    }
}