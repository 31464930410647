.title {
  cursor: pointer;
  color: #000;
  text-decoration: none;
  padding: 0;
  border: none;
  line-height: 20px;
  font-size: 14px;
  text-align: left;
}

.title:global.btn.btn-link:active,
.title:global.btn.btn-link:hover,
.title:global.btn.btn-link:focus {
  outline: none;
  box-shadow: none;
  color: #a593c8;
  text-decoration: none;
}

.room_photo {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 770px) {
  .room_photo {
    width: 100%;
    min-width: 100%;
    height: auto;
  }
}
