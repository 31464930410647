.fieldRow {
  display: flex;
  margin-right: -8px;
  margin-left: -8px;
  padding-left: 0;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (max-width: 479px) {
  .fieldRow {
    flex-direction: column;
  }
}