.confirmationNumber {
  font-family: Roboto,sans-serif;
  color: #8ac44a;
  font-size: 24px;
  line-height: 48px;
  font-weight: 400;
  letter-spacing: .27px;
  text-transform: uppercase;
} 

.container {
  display: block;
  max-width: 1140px;
  padding-right: 16px;
  padding-left: 16px;
  flex-direction: column;
  align-items: stretch;
  margin: auto;
}

.panel {
  display: flex;
  margin-bottom: 24px;
  padding: 24px 16px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background-color: #fff;
}