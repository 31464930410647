.cookie {
  color: #ffffff;
  text-decoration: underline;
  padding-right: 5px;
}

.emptyWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.title {
  font-family: Helvetica;
  font-size: 48px;
  color: #ffffff;
  margin-bottom: 40px;
  text-align: center;
  margin-top: 40%;
}

.subTitle {
  font-family: Helvetica;
  font-size: 20px;
  color: #ffffff;
  line-height: 30px;
  margin-bottom: 48px;
  text-align: center;
}

.headerContainer {
  position: relative;
  width: 100%;
}

.headerContainer :global .btn-link {
  color: white;
}

@media screen and (max-width:950px) {
  .title {
    line-height: 50px;
  }
}

@media screen and (max-width: 750px) {

  .title {
    display: none;
  }

  .subTitle {
    display: none;
  }
}
