.header {
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
}

.logo {
  width: 30%;
  height: 30%;
  border: 0;
  vertical-align: middle;
  display: inline-block;
  object-fit: contain;
}

.titleSection {
  display: flex;
  min-height: 80px;
  align-items: center;
  max-width: 100%;
}

.selectSection {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
}

@media screen and (max-width: 575px) {
  .header {
    flex-direction: column;
  }

  .selectSection,
  .titleSection {
    justify-content: center;
  }

  .selectSection {
    height: 40px;
  }
}