.navItem:not(:last-of-type)::after {
  content: '>';
}

.navItem:not(:last-of-type) {
  display: flex;
  align-items: center;
}

.link {
  padding: 4px;
  color: #000;
}

.link:hover {
  color: #a593c8 !important;
}

.link:global.nav-link.active {
  color: var(--button-color) !important;
  /* color: #000; */
  font-weight: bold;
}

.link:global.nav-link.active {
  /* color: var(--link-color); */
  color: #000 !important;
  background-color: initial !important;
}
