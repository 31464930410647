.carousel {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
}

.carousel :global .carousel-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.carouselItem {
  height: 100%;
}

.carouselItem img {
  object-fit: cover;
  height: 100%;
}

.carousel :global .carousel-indicators {
  z-index: 1;
}

.carousel :global .carousel-indicators li {
  width: 7px;
  height: 7px;
  background-color: rgba(255,255,255,.4);
  border-radius: 50%;
}

.carousel :global .carousel-indicators li.active {
  background-color: #fff;
}

.carousel :global .carousel-control-next,
.carousel :global .carousel-control-prev {
  width: 56px;
  opacity: 1;
}

.carousel :global .carousel-control-prev-icon {
  background-image: url('~static/icons-arrowleft.svg');
  
}

.carousel :global .carousel-control-next-icon {
  background-image: url('~static/icons-arrowright.svg');
}

.boxArrows :global .carousel-control-next,
.boxArrows :global .carousel-control-prev {
  background: var(--main-text-active-background);
  height: 56px;
  top: auto;
}

.boxArrows :global .carousel-control-prev {
  left: 0;
}

.boxArrows :global .carousel-control-next {
  left: 56px;
}

.carousel :global .carousel-control-prev-icon,
.carousel :global .carousel-control-next-icon {
  height: 24px;
  width: 24px;
}

@media screen and (max-width: 991px) {
  .boxArrows :global .carousel-control-next,
  .boxArrows :global .carousel-control-prev {
    background: transparent;
    height: auto;
    top: 0;
  }
  
  .boxArrows :global .carousel-control-prev {
    left: 0;
  }
  
  .boxArrows :global .carousel-control-next {
    left: auto;
  }
}