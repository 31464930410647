.columnNarrow {
  flex-grow: 1;
}

.tableHeaderWrapper {
  display: flex;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .tableHeaderWrapper {
    display: none;
  }
}
