.banner3 {
  display: grid;
  grid-template-columns: 48.5% 48.5%;

  column-gap: 3%;
}

.banner_wrapper {
  width: 90% !important;
  margin: auto;
}

.heading {
  margin-top: 5rem;
  color: #222222;
  font-size: 42px;
  font-weight: 500;
  letter-spacing: 0.01em;
  line-height: 47px;
}

@media screen and (max-width: 1280px) {
  .heading {
    color: #222222;
    font-size: 37px;
    font-weight: 500;
    letter-spacing: 0.01em;
    line-height: 40px;
  }
}

@media screen and (max-width: 1120px) {
  .heading {
    color: #222222;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0.01em;
    line-height: 31px;
  }
}

@media screen and (max-width: 760px) {
  .banner3 {
    display: grid;
    grid-template-columns: 100%;
  }
}
