.container {
  display: flex;
  padding-bottom: 16px;
}

.label {
  padding-right: 16px;
  flex: 1 2 33%;
}

.content {
  flex: 2 1 66%;
}

@media screen and (max-width: 991px) {
  .container {
    flex-direction: column;
  }
}