.toggle {
  z-index: 1;
  display: flex;
  height: 40px;
  padding: 0px 32px 0px 15px;
  align-items: center;
  color: var(--main-text-color);
}

.toggle:hover {
  color: var(--main-text-hover);
}

.toggle:global.btn:focus {
  box-shadow: none;
}

.dropdown :global .dropdown-menu {
  z-index: 999;
}