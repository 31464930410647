.modal:global.modal-dialog {
  max-width: 1140px;
}

.modal :global .modal-content {
  min-height: 600px;
  height: 100%;
}

.modalBody {
  display: flex;
  flex-direction: row;
}

.modalHeader:global.modal-header {  
  padding: 0;
  border: none;
}

.modalHeader h5 {
  line-height: 30px;
  margin-bottom: 8px;
}

.contentContainer {
  width: 100%;
}

.priceLabel {
  text-transform: capitalize;
  color: var(--label-text-color);
}

.roomPrice {
  font-size: 34px;
  line-height: 34px;
}

.pricePostfix {
  margin-left: 8px;
}

.modalFooter {
  justify-content: start;
}

.description {
  font-size: 16px;
  line-height: 1.75;
  letter-spacing: 0.44px;
  color: #333333;
  margin-bottom: 32px;
}

.facilities {
  color: var(--main-text-color);
  line-height: 1.43;
  letter-spacing: 0.27px;
}

.photosDesktop {
  display: block;
}

.photosMobile {
  display: none;
}

@media screen and (max-width: 575px) {
  .modal {
    margin: 0;
  }

  .modalHeader, .modalFooter {
    border-radius: 0;
  }

  .modal :global .modal-content {
    border-radius: 0;
    min-height: 100vh;
  }
}

@media screen and (max-width: 991px) {
  .modalBody {
    flex-direction: column;
  }

  .photosDesktop {
    display: none;
  }

  .photosMobile {
    display: block;
  }
}