.container {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 6;
  display: block;
  width: 100%;
  margin-bottom: 0px;
  padding: 16px 16px 16px;
  background-color: #fff;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
}