.minPriceContainer {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 56px;
  padding-right: 24px;
  margin-top: -56px;
  padding-bottom: 12px;
  padding-left: 24px;
  justify-content: center;
  align-items: flex-end;
  /* background-color: var(--main-text-active-background); */
  background-color: #111112c4;
}

.minPriceContainer:hover {
  text-decoration: none;
}

.minPriceContent {
  color: var(--main-text-active);
}

.minPrice {
  padding: 0 4px;
  font-size: 24px;
}
