.footer {
    margin-top: 100px !important;
}

.slider_small {
    /* height: -50%; */
}

.innerimage {
    width: 100%;
    height: 400px;
}

.fag_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
 
 .margin {
    margin-top: -10%;
} 