.containerLight {
  background-color: var(--background-light-color);
}

.containerDark {
  background-color: var(--background-dark-color);
}

.containerDark,
.containerLight {
  padding: 0 0;
}

.containerPadded {
  padding: 56px 0;
}

.containerInner {
  display: block;
  max-width: 1140px;
  padding-right: 15px;
  padding-left: 15px;
  align-items: stretch;
  margin: auto;
}

@media screen and (max-width: 575px) {
  .containerDark,
  .containerLight {
    padding: 24px 0;
  }
}