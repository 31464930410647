.popover {
  min-width: 310px;
  z-index: 3;
  width: 210px;
  padding: 14px;
  border: none;
  box-shadow: 0 1px 4px 0 hsla(0,0%,77.7%,.5);
  font-size: 12px;
}

.priceRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: rgba(0, 0, 0, 0.6);
}

.totalContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 14px;
  padding: 0;
}

.totalLabel {
  opacity: .85;
  font-size: 12px;
}

.totalPrice {
  color: rgba(0, 0, 0, 0.6);
  font-weight: bold;
}