
.menu:global.dropdown-menu {
  max-height: 400px;
  display: flex;
  flex-direction: column;
  min-width: 100%;
  padding: 10px;
}

.menu {
  background-color: #fff;
  display: none !important;
  z-index: 6;
  margin-top: 2px;
  margin-left: 0px;
  padding: 16px;
  align-self: flex-start;
  border-radius: 2px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.14);
}

.menu:global.show {
  display: flex !important;
}

.srollableOptions {
  height: 100%;
  overflow: auto;
}

.searchInput {
  margin-bottom: 8px;
  background-color: white;
  color: var(--main-text-color);
  box-shadow: none;
  display: flex;
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding-right: 8px;
  padding-left: 8px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--input-border-color);
  border-radius: 2px;
  
}

.searchInput:focus {
  outline: none;
  box-shadow: none;
}

.menuItem {
  background-color: #fff;
  padding: 4px 10px;
}

.menuItem:global.active {
  background-color: var(--main-text-active-background);
  color: var(--main-text-active);
}

.menuItem:hover {
  text-decoration: underline;
  background-color: #f4f4f5;
  color: var(--main-text-hover);
}
