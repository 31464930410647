.buttonCaption {
  margin-right: 4px;
  color: #fff;
}

.btn_color {
  background-color: #000 !important;
  color: #fff;
}

.btn_color:hover {
  background-color: #111112c4 !important;
}
