.sliderContainer {
  height: 540px;
  max-height: 50vh;
}

.floatingSearchContainer {
  position: relative;
}

@media screen and (max-width: 991px) {
  .sliderContainer {
    height: 480px;
  }
}

@media screen and (max-width: 767px) {
  .sliderContainer {
    height: 340px;
  }
}

@media screen and (max-width: 575px) {
  .sliderContainer {
    height: 200px;
  }
}