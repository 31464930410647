.popoverContent {
  padding: 0;
}

.popover {
  min-width: 310px;
  z-index: 3;
  width: 210px;
  padding: 14px;
  border: none;
  box-shadow: 0 1px 4px 0 hsla(0,0%,77.7%,.5);
  font-size: 12px;
}
