.tableContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
}

@media screen and (max-width: 767px) {
  .tableContainer {
    flex-direction: column;
  }
}
