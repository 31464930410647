@media screen and (max-width: 991px) {
  .buttonContainer {
    width: 100%;
    margin-left: 0;
  }
}

.btn_color {
  background-color: #000 !important;
}

.btn_color:hover {
  background-color: #111112c4 !important;
}
