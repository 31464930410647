.alert {
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 8px 8px 8px 24px;
  border-radius: 2px;
  color: #333;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.error {
  background-color: rgba(244, 108, 96, 0.2);
  background-image: url('~static/icons-error.svg');
  background-position: 4px 6px;
  background-size: auto;
  background-repeat: no-repeat;
}

.success {
  background-color: rgba(66, 189, 64, 0.2);
  background-image: url('~static/icons-success.svg');
  background-position: 4px 6px;
  background-size: auto;
  background-repeat: no-repeat;
}

.info {
  background-color: rgba(85, 119, 252, 0.2);
  background-image: url('~static/icons-info.svg');
  background-position: 4px 6px;
  background-size: auto;
  background-repeat: no-repeat;
}