.logo {
  width: 56px;
  height: 56px;
  border: 0;
  vertical-align: middle;
  display: inline-block;
  object-fit: contain;
}

.logoContainer {
  margin: 12px 12px 12px 0;
  display: flex;
  align-items: center;
}