.wrapper {
  display: flex;
  flex-direction: row;
  /* min-width: 1080px; */
}

.left {
  width: 100%;
  height: 100%;
  max-width: 1400px;
}
 
.right {
  width: 100%;
  position: relative;
}

@media (max-width: 960px){
  /* .right { 
    display: none;
  } */

  .wrapper {
    display: flex;
    flex-direction: column;
  }
}