.bed {
  display: flex;
  align-items: center;
  margin: 0.5rem;
  margin-left: 0;
  white-space: nowrap;
}

.roomTitle {
  font-weight: bold;
}

.bedIcon {
  margin-left: 0.5rem;
}