.link {
  display: flex;
  align-items: flex-start;
  width: fit-content;
  color: var(--main-text-color);
}

.link:hover {
  color: var(--main-text-hover);
}

.link[disabled] {
  color: var(--main-text-color);
  text-decoration: none;
  pointer-events: none;
}

.icon {
  margin-right: 8px;
}

.linkContent {
  margin: 0;
  font-size: 14px;
  white-space: pre-wrap;
  font-family: inherit;
  color: var(--main-text-color);
}